import React, { useState } from "react";
import footer_logo from "../assets/images/png/footerlogo.png";
import footer_img from "../assets/images/png/bottom-web.png";
import footerArrow from "../assets/images/svg/footer-arrow.svg";
import FooterMail from "../assets/images/svg/footer-mail.svg";

const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="container">
          <div className="row  position-relative align-items-end justify-content-between">
            <div className="col-lg-4 order-lg-1 order-3 ">
              <div className="mt-4 mt-lg-0">
                <img className="w-100" src={footer_img} alt="footer_img" />
              </div>
            </div>
            <div className="col-lg-2 order-lg-2 order-2 ">
              <div className="d-flex justify-content-center align-items-baseline">
                <h2 className="fs_lg footer_text_position text-center pb-4 mb-3 mb-sm-0 ms-3 ff_cooper text-white">
                  "What do you want to be
                  <span className="rem-color">remembered</span> for"
                </h2>
              </div>
            </div>
            <div className="col-lg-6 order-lg-3 order-1 ">
              <div className="d-block mb-4 d-lg-none">
                <div className="text-center">
                  <a href="#">
                    <img
                      className="mw_276 w-100"
                      src={footer_logo}
                      alt="footer_logo"
                    />
                  </a>
                </div>
              </div>
              <div className="py-sm-3 text-center ">
                <p className="text-center fs_sm text-white ff_font_semibold">
                  “ALL ABOARD <span className="ff_poppins">&</span> TAKE THE
                  TRIP”
                </p>
                <div className="d-flex justify-content-center">
                  <div className="footer_input_box rounded-pill w_lg_440 d-flex bg-white mb-lg-5 mb-4">
                    <label
                      for="MAil"
                      className="ps-3 d-flex align-items-center"
                    >
                      <img src={FooterMail} alt="FooterMail" />
                    </label>
                    <input
                      className="footer_input border-0 fs_sm text-black ff_font_semibold py-2 ps-2 w-100 bg-transparent"
                      type="text"
                      name=""
                      id="MAil"
                      placeholder="Get on the list"
                    />
                    <a
                      href="mailto:studio@lapinc.io"
                      className="pe-4 ps-3 d-flex align-items-center footer_submit_btn border-0"
                    >
                      <img src={footerArrow} alt="footerArrow" />
                    </a>
                  </div>
                </div>
                <div className="d-sm-inline-block">
                  <a
                    target="_blank"
                    href="https://twitter.com/lapinc_nft"
                    className="fs_2xl border-0 footer-btn-font twiter_btn text-white fw_regular d-inline-block"
                  >
                    Join Our Twitter
                  </a>
                </div>

                <div className="d-sm-inline-block ms-sm-4 mt-3 mt-sm-0 mb-4 mb-sm-0 common_btn">
                  <a
                    target="blank"
                    href="https://discord.com/invite/c5JhVa4geU"
                    className="fs_2xl border-0 footer-btn-font discord_btn text-white fw_regular d-inline-block"
                  >
                    Join Our Discord
                  </a>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="text-center">
                  <a href="#">
                    <img className="w-50" src={footer_logo} alt="footer_logo" />
                  </a>
                </div>
              </div>
              <p className="text-white footer_blue_box mt-4 mt-lg-0 ff_font_semibold mb-4 fs_sm lh-lg fs_sm text-center">
                LAPiNC is Leon and Pippa, LA and Perth, we are incorporated and
                collaborative, we are Lapis blue and pinc pink.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
