import React from "react";
import Slider from "react-slick";
import DemoProfile from "../assets/images/png/Demo-profile.png";
import Insta from "../assets/images/svg/insta.svg";
import Twiter from "../assets/images/svg/twitter.svg";
import Busines from "../assets/images/svg/business.svg";
import LinkedIn from "../assets/images/svg/linkedin.svg";
import Mail from "../assets/images/svg/mail.svg";
import janne_cooper from "../assets/images/png/janne_cooper.png";
import bessie from "../assets/images/png/bessie_cooper.png";
import jenny_wilson from "../assets/images/png/jenny_wilson.png";
import Developer from "../assets/images/png/developer.webp";
import Advisor from "../assets/images/png/advisor.webp";
import Simon from "../assets/images/png/communication.webp";
import Alfred from "../assets/images/png/alfred.webp";
import NftDeveloper from "../assets/images/png/nft.webp";
const Contribute = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          arrows: false,
          dots: true,
          autoplaySpeed: 2500,
          slidesToScroll: 1,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          arrows: false,
          dots: true,
          autoplaySpeed: 2500,
          slidesToScroll: 1,
          cssEase: "linear",
        },
      },
    ],
  };
  return (
    <>
      <section className="pb-4 pb-sm-5">
        <div className="container">
          <Slider
            {...settings}
            data-aos="fade-down"
            data-aos-duration="1500"
            data-aos-delay="200"
            className="d-md-none pt-4"
          >
            <div>
              <div>
                <img className="w-100" src={jenny_wilson} alt="jenny_wilson" />
              </div>
              <p className="text-white text-nowrap ff_font_bold text-center mb-1 fs_2xl">
                Leon Krasenstein
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Co-founder and Artist)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://mobile.twitter.com/friendsofleon"
                  target="blank"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  href="https://www.instagram.com/friendsofleongallery/?hl=en"
                  target="blank"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  href="mailto:leon@lapinc.io"
                  target="blank"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={bessie} alt="bessie" />
              </div>
              <p className="text-white ff_font_bold mb-1 mt-2 text-center fs_2xl">
                Pippa Mcmanus
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Co-founder and Artist)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="https://mobile.twitter.com/pippa_mcmanus"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  target="blank"
                  href="https://www.instagram.com/pippamcmanus/?hl=en"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="mailto:pippa@lapinc.io"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={janne_cooper} alt="janne_cooper" />
              </div>
              <p className="text-white ff_font_bold text-center my-1 fs_2xl">
                Daniel Radford
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Director of Operations)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="https://mobile.twitter.com/dradford73"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  target="blank"
                  href="https://www.instagram.com/daniel.radford73/?hl=en"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="mailto:studio@lapinc.io"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={NftDeveloper} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Generative NFT
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Web3 Tech and NFT Strategy Experts)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://generativenfts.io/"
                  target="blank"
                >
                  <img src={Busines} alt="Busines" />
                </a>
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/jimdee/"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://twitter.com\WendiDeeNFT"
                  target="blank"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100 px-2" src={Advisor} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Bridget Cleary
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Marketing Advisor)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/bridgetcleary/"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={Simon} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Simon Bailey
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Strategic Communications)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://au.linkedin.com/in/simon-bailey-75a74599"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={Alfred} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Alfred Mordecai
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Financial Advisor)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="mailto:al.mordecai@yahoo.com"
                  target="blank"
                >
                  <img src={Mail} alt="Twiter" />
                </a>
              </div>
            </div>
            <div>
              <div>
                <img className="w-100" src={Developer} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Genevieve Hoey
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Creative Consultant)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/genevieve-hoey-she-her-a45b992/?
originalSubdomain=au"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </Slider>
        </div>
        <div className="container d-none d-md-block">
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="row align-items-center mb-4 justify-content-center pt-4"
          >
            <div className="col-md-4 col-6">
              <div>
                <img className="w-100" src={NftDeveloper} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Generative NFT
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Web3 Tech and NFT Strategy Experts)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://generativenfts.io/"
                  target="blank"
                >
                  <img src={Busines} alt="Busines" />
                </a>
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/jimdee/"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://twitter.com\WendiDeeNFT"
                  target="blank"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div>
                <img className="w-100 px-2" src={Advisor} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Bridget Cleary
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Marketing Advisor)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/bridgetcleary/"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div>
                <img className="w-100" src={Simon} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Simon Bailey
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Strategic Communications)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://au.linkedin.com/in/simon-bailey-75a74599"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className="row align-items-center justify-content-center pt-4"
          >
            <div className="col-md-4 mt-4 mt-md-0 col-6">
              <div>
                <img className="w-100" src={Alfred} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Alfred Mordecai
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Financial Advisor)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="mailto:al.mordecai@yahoo.com"
                  target="blank"
                >
                  <img src={Mail} alt="Twiter" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div>
                <img className="w-100" src={Developer} alt="DemoProfile" />
              </div>
              <p className="text-white ff_font_bold mb-2 text-center fs_2xl">
                Genevieve Hoey
              </p>
              <p className="text-white text-nowrap ff_poppins fw_regular text-center fs_md">
                (Creative Consultant)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block mx-3 nav_social_hover"
                  href="https://www.linkedin.com/in/genevieve-hoey-she-her-a45b992/?
originalSubdomain=au"
                  target="blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contribute;
