import React, { useState } from "react";
import ear from "../assets/images/png/r-ear.png";
import nose from "../assets/images/png/r-nose.png";
import eye from "../assets/images/png/r-eye.png";
import lips from "../assets/images/png/r-lip.png";
import hand from "../assets/images/png/r-hand.png";
import plus from "../assets/images/png/r-plus.png";
import cross from "../assets/images/svg/cross.svg";

const Roadmap = () => {
  const [Effect, setEffect] = useState(false);
  const [Smell, setSmell] = useState(false);
  const [Taste, setTaste] = useState(false);
  const [Sound, setSound] = useState(false);
  const [Touch, setTouch] = useState(false);
  const [Plus, setPlus] = useState(false);

  return (
    <>
      <section id="Roadmap">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="container py-5"
        >
          <div className="d-flex flex-column justify-content-center">
            <p className="fs_sm ff_font_semibold text-center text-white mb-4">
              Our vision is to celebrate memory by creating a universe where
              yoursssss
              <span className="d-lg-block">
                NFT is a gateway to a new world of experiences and
                possibilities.
              </span>
            </p>
            <h2 className="fs_4xl raodmap_text position-relative index_1 ff_cooper text-center text_primary mb-3">
              ROADMAP
            </h2>
          </div>
          <p className="fs_sm ff_font_semibold text-center text-white mb-4 mb-sm-5">
            THE LAPiNC WORLD WILL BE EXPLORED ACCROSS ALL FIVE SENSES
          </p>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="roadmap_border">
                <div className="position-relative">
                  <div
                    className={`${
                      Sound ? "roadmap-modal-open rounded-2" : ""
                    } roadmap-modal-closed rounded-1 p-lg-5 p-2 bg_secondary z_index_99 roadmap_layers`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setSound(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="col-lg-6">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular mb-0 mb-sm-3 fs_3xxl">
                            Sound
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            The most important trigger of memory. Music and
                            storytelling are key to Our happiness. We look
                            forward to introducing you to the work our singers
                            and musicians will pair with our art.
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3 ">
                            We have 30 characters with their own history and
                            lore as told by our female playwrights and authors.
                            Our wonderful community will help contribute to
                            their stories and discussions will be held in
                            twitter spaces where all are encouraged to
                            <span className="ff-cabiral">'</span>have a say
                            <span className="ff-cabiral">'</span>.
                          </p>
                        </div>
                        <div className="col-sm-5 col-6">
                          <div className="text-center">
                            <img
                              className="roadmap-small-screen"
                              src={ear}
                              alt="sight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* SOUND OPENED  MODAL */}
                  <div
                    className={`${
                      Smell ? "rounded-2 roadmap-modal-open" : ""
                    } roadmap-modal-closed bg_secondary z_index_99 roadmap_layers rounded-1 p-lg-5 p-2`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setSmell(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="col-lg-6">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0 mb-sm-3">
                            Smell
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            Using generative technology, we aim to build unique
                            fragrances, similar to the way we layer perfumes
                            IRL. Based on a mix of themes from our collections,
                            classic fragrance notes and memories collected from
                            our community we hope to explore the power of scent
                            and the role it can play in a world that is
                            dominated by audio and visual stimulation.
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            Brain scientists have suggested the reason why scent
                            and memory are so closely linked is because of the
                            physical location of both of these regions in the
                            brain.
                          </p>
                        </div>
                        <div className="col-sm-5 col-6">
                          <div className="text-center">
                            <img
                              className="roadmap-small-screen"
                              src={nose}
                              alt="sight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      Taste ? "rounded-2 roadmap-modal-open" : ""
                    } roadmap-modal-closed bg_secondary z_index_99 roadmap_layers rounded-1 p-lg-5 p-2`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setTaste(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="col-lg-6">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0 mb-sm-3 ">
                            Taste
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            As many of us temporarily lost our taste during
                            covid, and were also unable to dine out, we have
                            realized just how much joy and happiness this sense
                            provides us with. A fleeting moment, but a lasting
                            memory.
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            We look forward to working with chefs and the food
                            obsessed to harness their creativity and vision of
                            LAPINC art through taste and flavor through
                            immersive experiences at events and exhibitions.
                          </p>
                        </div>
                        <div className="col-sm-5 col-6">
                          <div className="text-center">
                            <img
                              className="roadmap-small-screen"
                              src={lips}
                              alt="sight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      Effect ? "rounded-2 roadmap-modal-open" : ""
                    } roadmap-modal-closed bg_secondary z_index_99 roadmap_layers rounded-1 p-lg-5 p-2`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setEffect(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="col-lg-6">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular mb-0 mb-sm-3 fs_3xxl">
                            Sight
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            LAPINC have dedicated the last 12 months to making a
                            concept turn into a vision, literally. Pencil
                            sketches became Procreate sketches which then became
                            refined vector illustrations, 550 of them. Colours
                            clash and harmonise at the same time to create a
                            stimulating palette for the Viewer.
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            Veteran collaborators of digital art, LAPINC know
                            the importance of size having produced billboard
                            sized prints of our work since 2006 for exhibitions
                            in the USA and Australia.When illustrating the
                            artwork, files are zoomed in up to 8500
                            <span className="ff_cooper">%</span> to ensure the
                            look of everything is visually perfect for future
                            printing.
                          </p>
                        </div>
                        <div className="col-sm-5 col-6">
                          <div className="text-center">
                            <img
                              className="roadmap-small-screen"
                              src={eye}
                              alt="sight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TOUCH MODAL   */}
                  <div
                    className={`${
                      Plus ? "rounded-2 roadmap-modal-open" : ""
                    } roadmap-modal-closed bg_secondary z_index_99 roadmap_layers rounded-1 p-lg-5 p-2`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setPlus(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0 mb-sm-3">
                            HOW IT WORKS
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            To create a sensory effect on the art owner’s
                            senses, each What A Trip character is carefully
                            designed and assigned to its sensory theme. Using
                            the magic of Sight, Sound, Smell, Taste and Touch,
                            What A Trip will create memories during your
                            journey. Making it possible through custom scents
                            that are inspired by the collection, with music
                            composed by the artists to allow you to hear your
                            characters’ voices and feel their emotions with
                            signature tastes created by chefs and mixologists,
                            IRL meetups and Fashion events to touch the reality
                            with your web3 character.
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            These characters will be your guide, friend or a
                            white rabbit as this journey unfolds
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-0 mb-sm-3">
                            We are eager to create a community of passionate art
                            holders, ready to take the trip and experience our
                            world across the senses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* plus grid */}
                  <div
                    className={`${
                      Touch ? "rounded-2 roadmap-modal-open" : ""
                    } roadmap-modal-closed bg_secondary z_index_99 roadmap_layers rounded-1 p-lg-5 p-2`}
                  >
                    <div className="h-100">
                      <div className="d-flex justify-content-end position-sticky top-0">
                        <img
                          onClick={() => setTouch(false)}
                          className="cursor_pointer"
                          src={cross}
                          alt="cross"
                        />
                      </div>
                      <div className="row align-items-center h-100 justify-content-lg-between justify-content-center">
                        <div className="col-lg-6">
                          <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0 mb-sm-3">
                            Touch
                          </p>
                          <p className="text-white ff_font_light fs_sm mb-3">
                            Web3 is a contradiction of connection through
                            isolation. A big part of the community initiative is
                            IRL meet ups, parties and events where we can all
                            share stories and experiences. These will be a
                            celebration of memory through storytelling with a
                            different theme every time. As we are based across
                            the world from each other we have a vast choice of
                            locations to choose from, can not wait to see you
                            there<span className="ff_cooper">!</span>
                          </p>
                        </div>
                        <div className="col-sm-5 col-6">
                          <div className="text-center">
                            <img
                              className="roadmap-small-screen"
                              src={hand}
                              alt="sight"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* plus grid ends */}
                  <div class="roadmap_grid">
                    <div className="position-relative roadmap_box">
                      <img
                        onClick={() => setSound(true)}
                        className="w-100 cursor_pointer roadmap_imgs"
                        src={ear}
                        alt="ear"
                      />
                      <div
                        onClick={() => setSound(true)}
                        className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                      >
                        <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                          Sound
                        </p>
                      </div>
                    </div>
                    <div className="position-relative roadmap_box">
                      <img
                        onClick={() => setSmell(true)}
                        className="cursor_pointer roadmap_imgs w-100"
                        src={nose}
                        alt="nose"
                      />
                      <div
                        onClick={() => setSmell(true)}
                        className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                      >
                        <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                          Smell
                        </p>
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="position-relative roadmap_box">
                      <img
                        onClick={() => setEffect(true)}
                        className="cursor_pointer w-100 roadmap_imgs"
                        src={eye}
                        alt="eye"
                      />
                      <div
                        onClick={() => setEffect(true)}
                        className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                      >
                        <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                          Sight
                        </p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="position-relative roadmap_box">
                      <img
                        onClick={() => setTouch(true)}
                        className="cursor_pointer w-100 roadmap_imgs"
                        src={hand}
                        alt="hand"
                      />
                      <div
                        onClick={() => setTouch(true)}
                        className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                      >
                        <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                          Touch
                        </p>
                      </div>
                    </div>
                    <div className="position-relative roadmap_box">
                      <img
                        onClick={() => setTaste(true)}
                        className="w-100 roadmap_imgs cursor_pointer"
                        src={lips}
                        alt="lips"
                      />
                      <div
                        onClick={() => setTaste(true)}
                        className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                      >
                        <p className="text-white road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                          Taste
                        </p>
                      </div>
                    </div>
                    <div className="position-relative roadmap_box">
                      <div>
                        <img
                          className="w-100 cursor_pointer roadmap_imgs"
                          src={plus}
                          onClick={() => setPlus(true)}
                          alt="plus"
                        />
                        <div
                          onClick={() => setPlus(true)}
                          className="d-flex position-absolute w-100 h-100 top-0 start-0 roadmap_hover align-items-center justify-content-center"
                        >
                          <p className="text-white text-center road-text-glow-shadow ff_cooper fw_regular fs_3xxl mb-0">
                            HOW IT WORKS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
