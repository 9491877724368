import React from "react";
import AmyImg from "../assets/images/png/amy-new-img.png";
import LeftClouds from "../assets/images/png/left-clouds.png";
import RightClouds from "../assets/images/png/right-clouds.png";
// import RightClouds from "../assets/images/png/right-clouds.png";
import FlyClouds from "../assets/images/png/clouds-imgs.png";
import FlyClouds2 from "../assets/images/png/clouds-img-2.png";
import cloud2 from "../assets/images/png/amy-cloud2.png";
const Amy = () => {
  return (
    <>
      <section className="py-4 py-md-5 position-relative index_1">
        <img className="position-absolute cloud_2" src={cloud2} alt="cloud2" />
        <img
          className="position-absolute left_cloud"
          src={LeftClouds}
          alt="LeftClouds"
        />
        <img
          className="position-absolute right_cloud "
          src={RightClouds}
          alt="RightClouds"
        />
        <img
          className="clouds-anim position-absolute z-minus-5"
          src={FlyClouds}
          alt="FlyClouds"
        />
        <img
          className="clouds-anim-one position-absolute z-minus-5"
          src={FlyClouds2}
          alt="FlyClouds2"
        />
        <img
          className="clouds-anim-two position-absolute z-minus-5"
          src={FlyClouds2}
          alt="FlyClouds2"
        />
        <div className="container position-relative">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row align-items-center justify-content-center justify-content-sm-end">
                <div className="col-lg-5 col-sm-8">
                  <p className="fs_lg mx-4 ps-2 ps-sm-0 mb-3 mb-sm-0 mx-lg-0 ff_cooper text-white text-center lh-lg">
                    "My name is Amy, I am a friend of Alice's. Nice to meet you.
                    Will you be joining me on my trips?”
                  </p>
                </div>
                <div className="col-sm-4 col-10">
                  <div className="margin_bottom-2">
                    <img className="w-100 pe_md_33" src={AmyImg} alt="amy" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="rounded-1 position-relative mx-4 mx-sm-5 mx-xl-3 m index_1 blue_box">
                    <p className="fs_md text-center lh-lg mb-0 ff_font_semibold text-white">
                      LAPiNC<span className="ff_poppins">'</span>s decadent NFT
                      collection explores the fundamental human question of
                      identity. It introduces 30 fashionable characters each
                      with their own story. LAPiNC takes you to the core of your
                      memories utilizing all senses, helping you experience
                      nostalgia in a beautiful and unique way. The backstories
                      of each character was developed in collaboration with
                      female playwrights and authors to reflect an authentically
                      lived experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Amy;
