import "./App.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Switch, Route } from "react-router-dom";
import "aos/dist/aos.css";
import HomePage from "./HomePage";
import Genesis from "./components/Genesis";
import PreLoader from "./components/PreLoader";
import stylish_girl from "./assets/images/png/stylish-girl.webp";
import Gridline from "./assets/images/png/GridRoadline.webp";
import Gensis from "./assets/images/png/genesis_first.png";
import Leon from "./assets/images/png/Leon_pink.png";
import Sell from "./assets/images/png/Sell_light.png";
import news_1 from "./assets/images/png/news_1.webp";
import news_2 from "./assets/images/png/news_2.webp";
import news_3 from "./assets/images/png/news_3.webp";
import news_4 from "./assets/images/png/news_4.webp";
import news_5 from "./assets/images/png/news_5.webp";
import los_9 from "./assets/images/png/los-2007.png";
import eight_news from "./assets/images/png/eight-news.webp";
import girl_newspaper from "./assets/images/png/girl_news_9.webp";
import girl_9 from "./assets/images/png/girl-9.webp";
import party_9 from "./assets/images/png/group_9.webp";
import resident from "./assets/images/png/resident.png";
import blue_girl from "./assets/images/png/blue_girl.png";
import kimono_house from "./assets/images/png/kimono_house.webp";
import group_pic from "./assets/images/png/group_pic.webp";
import lvsp from "./assets/images/png/lvsp.png";
import lvsp_created from "./assets/images/png/lvsp-created.png";
import white_girl from "./assets/images/png/white-girl.webp";
import dream_girl from "./assets/images/png/dream-girl.webp";
import couple from "./assets/images/png/couple-devlopers.webp";
import live_news from "./assets/images/png/live-news.webp";
import news_girl from "./assets/images/png/girl-in-news.webp";
import pearth_2006 from "./assets/images/png/perth_2006.png";
import digital_news from "./assets/images/png/digital-news.webp";
import static_girl from "./assets/images/png/static-girl.webp";
import indian_express from "./assets/images/png/indian-express.webp";
import entertainement from "./assets/images/png/entertainment.webp";
import perth_2008 from "./assets/images/png/perth_2010.png";
import couple_party from "./assets/images/png/couple-party.webp";
import sydney_2010 from "./assets/images/png/sydney_2010.png";
import stylish_man from "./assets/images/png/stylish-man.webp";
import last_group from "./assets/images/png/group-last.webp";
import lvsplogo from "./assets/images/svg/lvsp.svg";

function App() {
  const images = [
    {
      image: Gridline,
    },
    {
      image: Gensis,
    },
    {
      image: Leon,
    },
    {
      image: Sell,
    },
    {
      image: news_1,
    },
    {
      image: news_2,
    },
    {
      image: news_3,
    },
    {
      image: news_4,
    },
    {
      image: news_5,
    },
    {
      image: los_9,
    },
    // 10
    {
      image: eight_news,
    },
    {
      image: girl_newspaper,
    },
    {
      image: girl_9,
    },
    {
      image: party_9,
    },
    {
      image: resident,
    },
    {
      image: blue_girl,
    },
    // 16
    {
      image: kimono_house,
    },
    {
      image: group_pic,
    },
    {
      image: lvsp,
    },
    {
      image: lvsp_created,
    },
    // 20
    {
      image: white_girl,
    },
    {
      image: dream_girl,
    },
    {
      image: couple,
    },
    {
      image: live_news,
    },
    {
      image: news_girl,
    },
    {
      image: pearth_2006,
    },
    {
      image: digital_news,
    },
    {
      image: static_girl,
    },
    {
      image: indian_express,
    },
    {
      image: entertainement,
    },
    {
      image: perth_2008,
    },
    // 31
    {
      image: couple_party,
    },
    {
      image: sydney_2010,
    },
    {
      image: stylish_man,
    },
    {
      image: stylish_girl,
    },
    {
      image: last_group,
    },
    {
      image: lvsplogo,
    },
  ];

  AOS.init({
    once: true,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      document.body.classList.remove("fix-screen");
    }, 3000);
  }, []);
  return (
    <>
      {loading && <PreLoader />}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/genesis"
          render={() => <Genesis images={images} />}
        />
      </Switch>
    </>
  );
}

export default App;
