import React from "react";
import janne_cooper from "../assets/images/png/janne_cooper.png";
import bessie from "../assets/images/png/bessie_cooper.png";
import jenny_wilson from "../assets/images/png/jenny_wilson.png";
import Insta from "../assets/images/svg/insta.svg";
import Twiter from "../assets/images/svg/twitter.svg";
import Mail from "../assets/images/svg/mail.svg";
const Team = () => {
  return (
    <>
      <section
        id="Team"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-delay="300"
      >
        <div className="container pt-sm-5 pt-4">
          <h2 className="ff_cooper fs_4xl fw_regular text_primary text-center">
            Our Team
          </h2>
          <div className="row d-none d-md-flex justify-content-center mb-4 pt-4">
            <div className="col-md-4 col-sm-6">
              <div>
                <img className="w-100" src={jenny_wilson} alt="jenny_wilson" />
              </div>
              <p className="text-white text-nowrap ff_font_bold text-center mb-1 fs_2xl">
                Leon Krasenstein
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Co-founder and Artist)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  href="https://mobile.twitter.com/friendsofleon"
                  target="blank"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  href="https://www.instagram.com/friendsofleongallery/?hl=en"
                  target="blank"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  href="mailto:leon@lapinc.io"
                  target="blank"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 ">
              <div>
                <img className="w-100" src={bessie} alt="bessie" />
              </div>
              <p className="text-white ff_font_bold mb-1 mt-2 text-center fs_2xl">
                Pippa Mcmanus
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Co-founder and Artist)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="https://mobile.twitter.com/pippa_mcmanus"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  target="blank"
                  href="https://www.instagram.com/pippamcmanus/?hl=en"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="mailto:pippa@lapinc.io"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div>
                <img className="w-100" src={janne_cooper} alt="janne_cooper" />
              </div>
              <p className="text-white ff_font_bold text-center my-1 fs_2xl">
                Daniel Radford
              </p>
              <p className="text-white text-nowrap ff_poppins fw_md text-center fs_md">
                (Director of Operations)
              </p>
              <div className="d-flex justify-content-center">
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="https://mobile.twitter.com/dradford73"
                >
                  <img src={Twiter} alt="Twiter" />
                </a>
                <a
                  className="d-inline-block nav_social_hover mx-3"
                  target="blank"
                  href="https://www.instagram.com/daniel.radford73/?hl=en"
                >
                  <img src={Insta} alt="Insta" />
                </a>
                <a
                  className="d-inline-block nav_social_hover"
                  target="blank"
                  href="mailto:studio@lapinc.io"
                >
                  <img src={Mail} alt="Mail" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
