import React from "react";
import StoryVector from "../assets/images/png/story-vector.webp";
const Story = () => {
  return (
    <>
      <section id="Story">
        <div className="container py-4 py-sm-5">
          <div className="row justify-content-center px-sm-4 px-2">
            <div className="col-xl-10">
              <div className="row justify-content-center rounded-1 mx-3 mx-sm-4 mx-xl-0 bg_light_dark pt-4">
                <div className="col-xl-4 col-md-8 col-10">
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h2 className="fs_4xl mb-4 text-center ff_cooper text_primary">
                      OUR STORY
                    </h2>
                    <img
                      data-aos="fade-left"
                      data-aos-duration="1500"
                      className="w-100"
                      src={StoryVector}
                      alt="StoryVector"
                    />
                  </div>
                </div>
                <div className="col-xl-8 mt-4 text-center mt-xl-0">
                  <div>
                    <p className="ff_font_light fs_sm text_primary text-center">
                      Australian artists Leon Krasenstein and Pippa McManus,
                      known collectively as LAPiNC, have been producing digital
                      works since 2006. LvsP, as they were once known, were
                      pioneers in the Australian digital art scene. They had
                      five sell<span className="ff-cabiral">-</span>out shows,
                      their work was featured in remarkable designer venues in
                      both the US and Australia, and they were commissioned for
                      large
                      <span className="ff-cabiral">-</span>scale art
                      installations in commercial venues.
                    </p>
                    <p className="ff_font_light fs_sm text_primary text-center">
                      Pippa is a globally recognized fashion illustrator who has
                      worked with well<span className="ff-cabiral">-</span>known
                      companies like Louis Vuitton and Mary Katrantzou. She has
                      also travelled as Converse
                      <span className="ff-cabiral">'</span>s artist ambassador
                      and has been invited to major fashion events all over the
                      world to illustrate fashion collections. She is currently
                      holding exhibitions in several galleries around the world
                      and creating large<span className="ff-cabiral">-</span>
                      scale murals in her hometown of Perth, Western Australia.
                    </p>
                    <p className="ff_font_light fs_sm text_primary text-center">
                      Leon is a Hollywood Costume Designer having most recently
                      designed costumes for Disney, created a film starring
                      Oscar winning actress Lupita Nyong
                      <span className="ff-cabiral">'</span>o and has dressed
                      notable stars One Direction, Andie MacDowell, Neil Patrick
                      Harris, and Jeff Goldblum. He has designed large operas
                      all over the world and even started a gallery in Sydney,
                      Australia that spotlights female artists.
                    </p>
                    <p className="ff_font_light fs_sm text_primary text-center">
                      After successful careers and time spent caring for family
                      members who have been diagnosed with Alzheimer’s, Leon and
                      Pippa were drawn back together in 2021 to create a new
                      collection of their digital artworks with a feminine
                      focus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Story;
