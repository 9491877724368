import React from "react";
import LapnicsNav from "./LapnicsNav";
import heading from "../assets/images/avif/main-logo.png";
import HeroImg from "../assets/images/avif/hero-img.webp";
import HeroText from "../assets/images/png/hero-text-what.png";
const Hero = () => {
  return (
    <>
      <div className="hero_section d-flex flex-column position-relative index_2">
        <div>
          <LapnicsNav />
        </div>
        <div className="flex-grow-1 d-flex justify-content-center flex-column">
          <div className="container">
            <div className="row">
              <div className="col-5 mx-auto">
                <img
                  className="w-100 scala-anim-img "
                  src={heading}
                  height="auto"
                  width="auto"
                  alt="heading"
                />
              </div>
            </div>
          </div>
          <div className="w-100">
            <img className="hero_img" src={HeroImg} alt="heading" />
          </div>
        </div>
        <div className="container text-center margin-30">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row justify-content-center">
                <div className="col-xl-5 col-md-6 col-sm-8 col-9">
                  <img
                    className="w-100 mb-sm-4 mb-3 mt-24 position-relative index_1"
                    src={HeroText}
                    alt="HeroText"
                  />
                </div>
              </div>
              <p className="ff_cooper fs_3xl text-white position-relative mb-5 index_1">
                LAUNCHING SOON
              </p>
              <div className="hero_text_box mx-4 mx-sm-5 mx-xl-3 rounded-1 text-center position-relative index_1">
                <p className="fs_lg ff_cooper lh-lg text_primary">
                  The debut NFT collection by internationally recognized
                  Australian artists Leon Krasenstein and Pippa McManus from
                  creative collective, LAPiNC.
                </p>
                <p className="fs_md ff_font_semibold lh-lg text-white mb-0">
                  WHAT A TRIP is a call to open your imagination and embark on a
                  journey into generative digital realms. It explores in a
                  playful way how people express, define, and redefine
                  themselves through what they wear. Inspired by Lewis Carol
                  <span className="ff_poppins">'</span>s Alice in Wonderland.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
