import React from "react";
import Accordion from "react-bootstrap/Accordion";
const Faqs = () => {
  return (
    <>
      <section id="FAQ’s" className="py-4 py-sm-5">
        <div className="container">
          <h2 className="fs_4xl ff_cooper text_primary text-center mb-4">
            FAQ’s
          </h2>
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            className="row justify-content-center"
          >
            <div className="col-xl-11">
              <Accordion className="pb-5 mb-0 mb-md-5">
                <Accordion.Item eventKey="3" className="">
                  <Accordion.Header>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      What is an NFTs?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 text-white  fs_lg ff_font_extrabold">
                      NFTs stand for “non fungible tokens”. When something has
                      fungibility, it means it is mutually interchangeable, so
                      Non<span className="ff_poppins">-</span>fungible means
                      that it can’t be exchanged for another asset of the same
                      type.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      What is a fungible assets?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 text-white  fs_lg ff_font_extrabold">
                      A “fungible” asset refers to something that is
                      interchangeable with another unit of that same asset. A
                      perfect example of a fungible asset is theAussie dollar.
                      If I exchange my <span className="ff_cooper">$</span>5
                      note for your <span className="ff_cooper">$</span>5 note,
                      nothing changes. While they are two different pieces of
                      pink plastic, both notes represent the same exact value.
                      That’s what fungibility is.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0" className="">
                  <Accordion.Header>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      What is LAPiNC?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      LAPINC are Australian artist duo Leon Krasenstein and
                      Pippa McManus who have been creating digital artworks
                      since 2006. Pioneers in the Australian digital art scene,
                      LvsP as they were formally known, having five sell out
                      exhibitions, private commissions and numerous corporate
                      fit<span className="ff_poppins">-</span>outs working with
                      large scale venues both in the US and Australia. Check out
                      our GENESIS page to view this 18yr friendship, partnership
                      and work created before WHAT A TRIP.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="">
                  <Accordion.Header>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      What is our Mission?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 text-white  fs_lg ff_font_extrabold">
                      Our mission is to build a community of like minded people,
                      bring more women into the space and to be here in the
                      metaverse for the long haul. The most important component
                      to LAPiNC is our dedication to making life easier for
                      those who have loved ones with Alzheimer
                      <span className="ff_poppins">'</span>s disease. As two
                      people currently in this position, we are dedicated to
                      providing relief through the metaverse. We are doing it
                      for Grant, Sonia and Bill.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="">
                  <Accordion.Header>
                    <p className="mb-0 text-white fs_lg ff_font_extrabold">
                      How many NFTs in the collection?
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 text-white  fs_lg ff_font_extrabold">
                      The collection comprises of 8282 NFT’s simply because both
                      founders were born in 1982.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
