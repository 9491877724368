import React from "react";
import LapnicsNav from "./LapnicsNav";

const Genesis = ({ images }) => {
  return (
    <>
      <section className="d-none d-md-block Gridline_bg pb-5">
        <LapnicsNav />
        <section className="min-vh-100 mt-5 pb-5 d-flex flex-column justify-content-center position-relative">
          <img
            className="h-100 top_35px position-absolute start-50 translate-middle-x bottom-0"
            src={images[0].image}
            alt="Gridline"
          />
          <div className="container">
            <div className="row justify-content-between ">
              <div className="col-md-6 pe-4">
                <div className="item_gensis my-5">
                  <img
                    data-aos="fade-left"
                    data-aos-delay="200"
                    data-aos-duration="600"
                    className="w-35-percent"
                    src={images[1].image}
                    alt="Gensis"
                  />
                </div>
                <p className=" pb-4 ps-5 ms-5 fs_sm ff_font_light">
                  MANY YEARS BEFORE LAPiNC
                </p>
                <div className="d-grid d_grid gap-4 ">
                  <div className="item_news_1 w-100">
                    <img
                      data-aos="fade-left"
                      data-aos-delay="700"
                      data-aos-duration="1000"
                      className="w-100 ps-3"
                      src={images[2].image}
                      alt=""
                    />
                  </div>
                  <div className="item_news_2 mt-4">
                    <img
                      data-aos="fade-left"
                      data-aos-delay="1300"
                      data-aos-duration="900"
                      className="w-100 "
                      src={images[3].image}
                      alt="Sell"
                    />
                  </div>
                  <div className="item_news_3 d-flex justify-content-center">
                    <img
                      data-aos="fade-left"
                      data-aos-delay="1000"
                      data-aos-duration="900"
                      className="w-75"
                      src={images[4].image}
                      alt="news_1"
                    />
                  </div>
                  <div className="item_news_4">
                    <img
                      data-aos="fade-left"
                      data-aos-delay="1000"
                      data-aos-duration="900"
                      className=" w-100 h-75"
                      src={images[5].image}
                      alt="news_2"
                    />
                  </div>
                  <div className="item_news_5">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[6].image}
                      alt="news_3"
                    />
                  </div>
                  <div className="item_news_6">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w_80"
                      src={images[7].image}
                      alt="news_4"
                    />
                  </div>
                  <div className="item_news_7 d-flex justify-content-center">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100 px-5"
                      src={images[8].image}
                      alt="news_5"
                    />
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  className="d-flex justify-content-end"
                >
                  <img
                    className="w_60 py-5 los-7"
                    src={images[9].image}
                    alt="los_9"
                  />
                </div>
                <div className="d-grid gap-4">
                  <div className="item-8 w-100 d-flex justify-content-end">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100 ps-5"
                      src={images[10].image}
                      alt="eight_news"
                    />
                  </div>
                  <div className="item-9 w-100">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[11].image}
                      alt="girl_newspaper"
                    />
                  </div>
                  <div className="item-10 w-100 d-flex justify-content-center">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-75"
                      src={images[12].image}
                      alt="girl_9"
                    />
                  </div>
                  <div className="item-11 w-100">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[13].image}
                      alt="party_9"
                    />
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  className="d-flex position-relative index_-1 justify-content-end"
                >
                  <img
                    className="w-75 py-5 los-7"
                    src={images[14].image}
                    alt="los_9"
                  />
                </div>
                <div className="d-grid">
                  <div className="item-12 w-100">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[15].image}
                      alt=""
                    />
                  </div>
                  <div className="item-13 w-100">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[16].image}
                      alt=""
                    />
                  </div>
                  <div className="item-14 w-100 ps-4 d-flex justify-content-center">
                    <img
                      data-aos="fade-left"
                      data-aos-duration="900"
                      className="w-75"
                      src={images[17].image}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 pl_32 mt-5">
                <div className="d-flex align-items-center">
                  <div className="w-50 lvsp h-50">
                    <img
                      data-aos="fade-right"
                      data-aos-delay="500"
                      data-aos-duration="900"
                      className="w-50 h-100"
                      src={images[18].image}
                      alt="lvsp"
                    />
                  </div>
                  <p className="fs_sm ff_font_light text-center">
                    THIS ARTIST DUO WERE <span className="d-md-block"></span>
                    FORMALLY KNOWN AS LVSP
                  </p>
                </div>
                <div className="d-grid d_grid gap-3">
                  <div className="right_item-1  w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-delay="1000"
                      data-aos-duration="600"
                      className="w_120"
                      src={images[19].image}
                      alt="lvsp_created"
                    />
                  </div>
                  <div className="right_item-2 w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      data-aos-delay="1000"
                      className="w-100"
                      src={images[20].image}
                      alt="white_girl"
                    />
                  </div>
                  <div className="right_item-3 w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      data-aos-delay="1000"
                      className="w_85"
                      src={images[21].image}
                      alt="dream_girl"
                    />
                  </div>
                  <div className="right_item-4 w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      data-aos-delay="1000"
                      className="w-100"
                      src={images[22].image}
                      alt="lvsp_created"
                    />
                  </div>
                  <div className="right_item-5 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[23].image}
                      alt="live_news "
                    />
                  </div>
                  <div className="right_item-6 w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[24].image}
                      alt="news_girl"
                    />
                  </div>
                  <div className="right_item-7 w-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="600"
                      className="w-100"
                      src={images[25].image}
                      alt="pearth_2006"
                    />
                  </div>
                  <div className="right_item-8 w-100 h-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100 h-75"
                      src={images[27].image}
                      alt="static_girl"
                    />
                  </div>
                  <div className="right_item-9 w-100 h-100">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100 h-100"
                      src={images[26].image}
                      alt="ldigital_news"
                    />
                  </div>
                  <div className="right_item-10 pe-5 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[28].image}
                      alt="indian_express"
                    />
                  </div>
                  <div className="right_item-11 ps-5 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[29].image}
                      alt="entertainement"
                    />
                  </div>
                  <div className="right_item-12 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="600"
                      className="w-100"
                      src={images[30].image}
                      alt="perth_2008"
                    />
                  </div>
                  <div className="right_item-13 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[31].image}
                      alt="couple_party"
                    />
                  </div>
                  <div className="right_item-14 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="600"
                      className="w-100"
                      src={images[32].image}
                      alt="sydney_2010"
                    />
                  </div>
                  <div className="right_item-15 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[33].image}
                      alt="stylish_man"
                    />
                  </div>
                  <div className="right_item-16 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[34].image}
                      alt="stylish_girl"
                    />
                  </div>
                  <div className="right_item-17 w-100 ">
                    <img
                      data-aos="fade-right"
                      data-aos-duration="900"
                      className="w-100"
                      src={images[35].image}
                      alt="last_group"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="pb-5 d-md-none Gridline_bg">
        <LapnicsNav />
        <div className="container">
          <div className="text-center w-100">
            <img className="w-50" src={images[1].image} alt="Gensis" />
          </div>
          <div className="d-flex justify-content-evenly mt-5">
            <div className="text-center">
              <p className="text-black mb-0 fs_sm ff_font_light">
                MANY YEARS BEFORE LAPiNC
              </p>
              <p className="text-black mb-0 fs_sm ff_font_light">
                THIS ARTIST DUO WERE
              </p>
              <p className="text-black mb-0 fs_sm ff_font_light">
                FORMALLY KNOWN AS LVSP
              </p>
            </div>
            <div className="w-25">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 ms-sm-5"
                src={images[36].image}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="w-50 pt-4">
          <img
            data-aos-offset="200"
            data-aos="zoom-in"
            data-aos-duration="900"
            className="w-100"
            src={images[2].image}
            alt="Leon"
          />
        </div>
        <div className="w-100 pt-4 me-0 d-flex justify-content-end ">
          <img
            data-aos-offset="200"
            data-aos="zoom-in"
            data-aos-duration="900"
            className="w-50 neg_mb_20"
            src={images[19].image}
            alt="lvsp_created"
          />
        </div>
        <div className="w-50 ">
          <img
            data-aos-offset="200"
            data-aos="zoom-in"
            data-aos-duration="900"
            className="w-100 neg_mb"
            src={images[3].image}
            alt="sell"
          />
        </div>
        <div className="container">
          <div className="d-grid gap-3">
            <div className="grid1 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[21].image}
                alt="dream_girl"
              />
            </div>
            <div className="grid2 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[4].image}
                alt="news_1"
              />
            </div>
            <div className="grid3 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className=" w-100"
                src={images[5].image}
                alt="news_2"
              />
            </div>
            <div className="grid4 pb-1 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[20].image}
                alt="white_girl"
              />
            </div>
            <div className="grid5 w-100 h-100 ">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100 object_fit_cover "
                src={images[23].image}
                alt="live_news "
              />
            </div>
            <div className="grid6 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[22].image}
                alt="lvsp_created"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end pt-5">
            <img
              data-aos-offset="200"
              data-aos="zoom-in"
              data-aos-duration="900"
              className="w_60 "
              src={images[25].image}
              alt="pearth_2006"
            />
          </div>
          <div className="d-grid gap-3">
            <div className="grid_2006_1 w-100 h-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100 "
                src={images[26].image}
                alt="ldigital_news"
              />
            </div>
            <div className="grid_2006_2 w-100 h-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100"
                src={images[27].image}
                alt="static_girl"
              />
            </div>
            <div className="grid_2006_3 w-100 h-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100 object_fit_cover"
                src={images[7].image}
                alt="news_4"
              />
            </div>
            <div className="grid_2006_4 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[24].image}
                alt="news_girl"
              />
            </div>
            <div className="grid_2006_5 d-flex justify-content-center">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 px-2"
                src={images[8].image}
                alt="news_5"
              />
            </div>
            <div className="grid_2006_6 w-100 ">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[28].image}
                alt="indian_express"
              />
            </div>
            <div className="grid_2006_7 px-4">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 "
                src={images[6].image}
                alt="news_3"
              />
            </div>
          </div>
        </div>
        <div className="">
          <img
            data-aos-offset="200"
            data-aos="zoom-in"
            data-aos-duration="900"
            className=" pt-5 pb-3 w_60"
            src={images[9].image}
            alt="los_9"
          />
        </div>
        <div>
          <div className="d-flex justify-content-center w-100 ">
            <img
              data-aos-offset="200"
              data-aos="zoom-in"
              data-aos-duration="900"
              className="w-100 px-3"
              src={images[29].image}
              alt="entertainement"
            />
          </div>
          <div className="px-2 pt-4 w-100 d-flex justify-content-center">
            <img
              data-aos-offset="200"
              data-aos="zoom-in"
              data-aos-duration="900"
              className="w-100"
              src={images[10].image}
              alt="eight_news"
            />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end pt-5 pb-3 w-100 ">
            <img
              data-aos-offset="200"
              data-aos="zoom-in"
              data-aos-duration="900"
              className="w_60"
              src={images[30].image}
              alt="perth_2008"
            />
          </div>
        </div>
        <div className="container">
          <div className="d-grid gap-3">
            <div className="grid_2008_1 w-100 d-flex justify-content-center">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100 object_fit_cover"
                src={images[12].image}
                alt="girl_9"
              />
            </div>
            <div className="grid_2008_2 w-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-75 object_fit_cover"
                src={images[11].image}
                alt="girl_newspaper"
              />
            </div>
            <div className=" w-100 grid_2008_3">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[31].image}
                alt="couple_party"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center px-2 py-3 w-100">
            <img
              data-aos-offset="200"
              data-aos="zoom-in"
              data-aos-duration="900"
              className="w-100"
              src={images[13].image}
              alt="party_9"
            />
          </div>
        </div>
        <div className="pt-4 pb-2 w-75 ">
          <img
            data-aos-offset="200"
            data-aos="zoom-in"
            data-aos-duration="900"
            className="w-100"
            src={images[32].image}
            alt="sydney_2010"
          />
        </div>
        <div class="container mb-5">
          <div class="d-grid gap-3 mb-5">
            <div class="grid_2010_1 h-100">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[15].image}
                alt="group_pic"
              />
            </div>
            <div class="grid_2010_2 d-flex justify-content-center">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-75"
                src={images[17].image}
                alt="group_pic"
              />
            </div>
            <div class="grid_2010_3">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100 h-100 object_fit_cover py-5"
                src={images[34].image}
                alt="stylish_girl"
              />
            </div>
            <div class="grid_2010_4 pt-3">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[35].image}
                alt="last_group"
              />
            </div>
            <div class="grid_2010_5 px-3 pt-3">
              <img
                data-aos-offset="200"
                data-aos="zoom-in"
                data-aos-duration="900"
                className="w-100"
                src={images[33].image}
                alt="stylish_man"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Genesis;
