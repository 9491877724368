import React from "react";
import LeftClouds from "../assets/images/png/traits-left-cloud.png";
import RightClouds from "../assets/images/png/traits-right-cloud.png";
import Traits1 from "../assets/images/png/traits11.gif";
import Traits2 from "../assets/images/png/traits22.gif";
import Traits3 from "../assets/images/png/traits3.gif";
import Traits4 from "../assets/images/png/traits4.gif";
import Traits5 from "../assets/images/png/traint-new.gif";
import Traits6 from "../assets/images/png/traits6.gif";
import Traits7 from "../assets/images/png/traits7.gif";
import Traits8 from "../assets/images/png/traits8.gif";
import Traits9 from "../assets/images/png/traits99.gif";
const Traits = () => {
  return (
    <>
      <section id="Traits" className="position-relative index_1">
        <img
          className="position-absolute d-none d-xl-block Left_traits_Clouds start-0 bottom-0"
          src={LeftClouds}
          alt="LeftClouds"
        />
        <img
          className="position-absolute d-none d-xl-block Right_traits_Clouds end-0 bottom-0"
          src={RightClouds}
          alt="RightClouds"
        />
        <div className="container py-4 py-sm-5">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="traits_grid">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="500"
                  data-offset="500"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits4} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="700"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits3} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="800"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits7} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1000"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits1} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1200"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits5} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1400"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits2} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1600"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits6} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="2000"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits9} alt="TraitsColor" />
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1800"
                  className="traits_border"
                >
                  <img className="w-100" src={Traits8} alt="TraitsColor" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Traits;
