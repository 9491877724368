import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "./components/Hero";
import Story from "./components/Story";
import Traits from "./components/Traits";
import Roadmap from "./components/Roadmap";
import Amy from "./components/Amy";
import Team from "./components/Team";
import Faqs from "./components/Faqs";
import RotateSlider from "./components/RotateSlider";
import Footer from "./components/Footer";

import BackToTop from "./components/BackToTop";

import Contribute from "./components/Contribute";
import Memory from "./components/Memory";

const HomePage = () => {
  return (
    <div>
      <div className="overflow-x-hidden">
        <Hero />
        <Amy />
        <Story />
        <Traits />
        <RotateSlider />
        <Roadmap />
        {/* <Memory /> */}
        <Team />
        <Contribute />
        <Faqs />
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
