import React, { useState } from "react";
import NavInsta from "../assets/images/svg/nav-insta.svg";
import NavDiscord from "../assets/images/svg/nav-discord.svg";
import NavTwiter from "../assets/images/svg/nav-twiter.svg";

import { Link } from "react-router-dom";
const LapnicsNav = () => {
  const [navBar, setnavBar] = useState(false);
  if (navBar) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  const pathname = window.location.pathname;
  return (
    <>
      <nav>
        <div className="container py-4">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <a
                className="d-inline-block nav_social_hover"
                target="_blank"
                href="https://www.instagram.com/lapinc_nft/?hl=en"
              >
                <img src={NavInsta} alt="NavInsta" />
              </a>
              <a
                className="mx-4 d-inline-block cursor_poniter nav_social_hover"
                href="https://discord.com/invite/c5JhVa4geU"
                target="_blank"
              >
                <img src={NavDiscord} alt="NavInsta" />
              </a>
              <a
                className="d-inline-block nav_social_hover"
                target="_blank"
                href="https://twitter.com/lapinc_nft"
              >
                <img src={NavTwiter} alt="NavInsta" />
              </a>
            </div>
            <ul className="d-none d-lg-flex ps-0 mb-0">
              <li className="nav-links">
                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black"
                      href="#Story"
                    >
                      HOME
                    </a>
                  </Link>
                ) : null}
                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black ms-3"
                      href="#Story"
                    >
                      OUR STORY
                    </a>
                  </Link>
                ) : (
                  <a
                    className="ff_font_regular text_primary main_nav_links_hover ms-3"
                    href="#Story"
                  >
                    OUR STORY
                  </a>
                )}

                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black ms-3 text-uppercase"
                      href="#Roadmap"
                    >
                      Roadmap
                    </a>
                  </Link>
                ) : (
                  <a
                    className="ff_font_regular text_primary main_nav_links_hover ms-3 text-uppercase"
                    href="#Roadmap"
                  >
                    Roadmap
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black ms-3 text-uppercase"
                      href="#Traits"
                    >
                      Traits
                    </a>
                  </Link>
                ) : (
                  <a
                    className="ff_font_regular text_primary main_nav_links_hover ms-3 text-uppercase"
                    href="#Traits"
                  >
                    Traits
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black ms-3 text-uppercase"
                      href="#Team"
                    >
                      Team
                    </a>
                  </Link>
                ) : (
                  <a
                    className="ff_font_regular text_primary main_nav_links_hover ms-3 text-uppercase"
                    href="#Team"
                  >
                    Team
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link
                    className="ff_font_regular genesis_hover text-black ms-3 text-uppercase"
                    to="genesis"
                  >
                    Genesis
                  </Link>
                ) : (
                  <Link
                    className="ff_font_regular text_primary main_nav_links_hover ms-3 text-uppercase"
                    to="genesis"
                  >
                    Genesis
                  </Link>
                )}
                {pathname === "/genesis" ? (
                  <Link to="/">
                    <a
                      className="ff_font_regular genesis_hover text-black ms-3 text-uppercase"
                      href="#FAQ’s"
                    >
                      FAQ’s
                    </a>
                  </Link>
                ) : (
                  <a
                    className="ff_font_regular text_primary main_nav_links_hover ms-3 text-uppercase"
                    href="#FAQ’s"
                  >
                    FAQ’s
                  </a>
                )}
              </li>
            </ul>
            <button
              onClick={() => setnavBar(true)}
              className="bg-transparent border-0 d-lg-none ms-lg-5 ms-3 menu position-relative"
            >
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </button>
          </div>
        </div>
        <div className={`${navBar ? "show-nav" : "nav-fix"}`}>
          <div className="min-vh-100 position-relative d-flex justify-content-center align-items-center">
            <button
              onClick={() => setnavBar(false)}
              className="btn btn-close position-absolute top-0 end-0 mt-4 me-4 fs-1 btn-close-white"
            ></button>
            <ul className="mb-0 ps-0">
              <li className="d-flex flex-column text-center">
                {pathname === "/genesis" ? (
                  <Link className="mb-3" to="/">
                    <a className="ff_font_regular text-white" href="#Story">
                      HOME
                    </a>
                  </Link>
                ) : null}
                {pathname === "/genesis" ? (
                  <Link className="my-3" to="/">
                    <a
                      onClick={() => setnavBar(false)}
                      className="ff_font_regular text-white"
                      href="#Story"
                    >
                      OUR STORY
                    </a>
                  </Link>
                ) : (
                  <a
                    onClick={() => setnavBar(false)}
                    className="ff_font_regular text-white my-3"
                    href="#Story"
                  >
                    OUR STORY
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link className="my-3" to="/">
                    <a
                      onClick={() => setnavBar(false)}
                      className="ff_font_regular text-white"
                      href="#Roadmap"
                    >
                      ROADMAP
                    </a>
                  </Link>
                ) : (
                  <a
                    onClick={() => setnavBar(false)}
                    className="ff_font_regular text-white my-3"
                    href="#Roadmap"
                  >
                    ROADMAP
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link className="my-3" to="/">
                    <a
                      onClick={() => setnavBar(false)}
                      className="ff_font_regular text-white"
                      href="#Traits"
                    >
                      TRAITS
                    </a>
                  </Link>
                ) : (
                  <a
                    onClick={() => setnavBar(false)}
                    className="ff_font_regular text-white my-3"
                    href="#Traits"
                  >
                    TRAITS
                  </a>
                )}
                {pathname === "/genesis" ? (
                  <Link className="my-3" to="/">
                    <a
                      onClick={() => setnavBar(false)}
                      className="ff_font_regular text-white"
                      href="#Team"
                    >
                      TEAM
                    </a>
                  </Link>
                ) : (
                  <a
                    onClick={() => setnavBar(false)}
                    className="ff_font_regular text-white my-3"
                    href="#Team"
                  >
                    TEAM
                  </a>
                )}

                <Link
                  onClick={() => setnavBar(false)}
                  className="ff_font_regular text-white my-3 text-uppercase"
                  to="genesis"
                >
                  Genesis
                </Link>
                {pathname === "/genesis" ? (
                  <Link className="my-3" to="/">
                    <a
                      onClick={() => setnavBar(false)}
                      className="ff_font_regular text-white"
                      href="#Faq"
                    >
                      FAQS
                    </a>
                  </Link>
                ) : (
                  <a
                    onClick={() => setnavBar(false)}
                    className="ff_font_regular text-white my-3"
                    href="#FAQ’s"
                  >
                    FAQS
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LapnicsNav;
