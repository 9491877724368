import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderimg1 from "../assets/images/png/B1.png";
import sliderimg2 from "../assets/images/png/B2.png";
import sliderimg3 from "../assets/images/png/B3.png";
import sliderimg4 from "../assets/images/png/B4.png";
import sliderimg5 from "../assets/images/png/B5.png";

const RotateSlider = () => {
  return (
    <div id="Genesis" className="overflow-hidden py-5">
      <div className=""></div>
      <div className="slider-straight adjust">
        <div className="slide-track-straight">
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg2} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img " src={sliderimg1} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg3} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg5} alt="team-man" />
          </div>
          <div class="slide mx-2">
            <img className="slider-img" src={sliderimg4} alt="team-man" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RotateSlider;
